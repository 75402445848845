<template>
  <div class="enterprise-notice">
    <div class="font-18" style="line-height:1.8">
      <div class="font-bold font-24 mb-20" style="text-align:center">签署合同</div>
    <div class="font-18 " style="text-align: center;">审核通过后 ，一到两个工作日之内等待电话通知。</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";
export default defineComponent({
  name: "EnterpriseContract",
  emits: ["update"],
  setup(props, { emit }) {
    return {  };
  }
});
</script>

<style></style>
