<template>
  <div class="enterprise-notice">
    <div class="font-18" style="line-height: 1.8">
      <div class="font-bold font-24 mb-20" style="text-align: center">
        园区入驻须知
      </div>
      <div v-html="park_notice"></div>
      <!-- <div>
        （一）被国家、省、市级评为人力资源诚信服务示范机构或被评定AAA级以上等级人力资源服务机构。
      </div>
      <div>
        （二）省人力资源服务行业协会常务理事及以上单位、市级人力资源服务行业协会主要理事单位。
      </div>
      <div>
        （三）已入驻其他省级以上人力资源产业园的人力资源服务机构。
      </div>
      <div>
        （四）年度经营收入1000万元以上的人力资源派遣类和服务外包类机构；年度经营收入100万元以上的人力资源培训和高级人才寻访机构。
      </div>
      <div>
        （五）其他优秀的人力资源服务机构。
      </div> -->
      <!-- <div class="font-bold mb-10">（一）入驻对象</div>
      <div>
        孵化企业（以下简称A类企业）：项目领衔者为大学本科及以上毕业生、海外留学生和工程技术类中级及以上职称人员。孵化项目需具备以下条件：
      </div>
      <div>
        1．以从事高新技术产品的开发与生产为主业，项目属于国家颁布的高新技术领域，或与现有国内技术相比有明显的创新和进步。
      </div>
      <div>
        2．项目技术、工艺条件小试成熟，具备商品化、产业化的条件；需要申请许可证照的，已经提出申请或者具备申请条件。
      </div>
      <div>
        3．项目技术来源清晰，已申请专利或者申请著作权登记或者采取了适当的保密措施等，无知识产权权属纠纷。
      </div>
      <div>
        4．有较强的技术开发力量和较好的技术开发条件，有实施孵化项目的技术能力和后续开发能力。
      </div>
      <div>5．有开办企业和实施孵化项目所需要的基本资金。</div>
      <div>6．市场发展潜力大，预期的经济与社会效益显著。</div>
      <div>7．无环境污染或者经过处理后无污染物排放。</div>
      <div>
        其他企业（以下简称B类企业）：科创园除引进A类企业外，鼓励符合下列条件的B类企业入驻：
      </div>
      <div>
        1．科技中介企业：经工商部门登记注册，提供技术咨询或专利事务服务的机构。
      </div>
      <div>
        2．创业（风险）投资企业：符合国家《创业投资企业管理暂行办法》规定注册成立的创业（风险）投资企业和主要为入驻企业提供金融服务的单位。
      </div>
      <div>
        3．研发与测试企业：经工商登记以产品和软件开发、快速成形、测试等为主要经营内容的企业。
      </div>
      <div>
        4．设计创意企业：具有一定规模和实力的各类设计、信息咨询、创意等知识型服务企业。
      </div>
      <div class="font-bold mb-10 mt-10">（二）入驻要求</div>
      <div>
        1、已经取得法人资格，产权明晰，自主经营、自负盈亏、运行状况良好的企业。
      </div>
      <div>2、没有企业结构的自然人，具有开发优势和自我发展潜能的科技人员。</div>
      <div>3、从事高科技产品的开发、研究和生产的科技型企业。</div>
      <div>4、开发能力强，并以自主开发为主，技术水平较高的企业。</div>
      <div>
        5、项目来源清晰，无专利冲突，拥有项目的合法产权，并且手续完整，项目有转化生产的可能，并
        有商品化后 好的市场前景和高的附加值的企业。
      </div>
      <div>
        6、企业负责人有科技开发和管理才能，熟悉本行业的发展状况，讲求团队合作。
      </div>
      <div>
        7、
        项目工艺先进，安全性好，对环境不造成污染，符合中国境内本行业的有关规定。
      </div>
      <div>
        8、从事科技部、财政部、国家税务部局共同编制的《中国高新技术产品目录》所列产品的研究、开发、生产和经营。
      </div>
      <div>9、接受孵化器的管理。</div>
      <div class="font-bold mb-10 mt-10">（三）入驻义务</div>
      <div>1、入驻企业必须遵守国家的有关法律法规、合法经营；</div>
      <div>
        2、入驻企业遵守与科创园所签订的租赁合同等与入驻相关的协议及科创园制定的管理规章制度；
      </div>
      <div>
        3、入驻企业有义务向科创园报送不涉及企业技术秘密的报表和统计数据，支持科创园完成上级部门要求的统计上报工作；
      </div>
      <div>4、入驻企业需服从科创园统一管理，配合科创园的日常工作。</div> -->
    </div>
    <attachment :data="file"></attachment>
    <a-button block size="large" type="primary" class="btn" @click="goNextStep">
      我已了解
    </a-button>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  computed
} from "@vue/runtime-core";
import Attachment from "@/components/attachment.vue";
import { getParkData } from "@/api/main.js";
import { getUserData } from "@/utils/storeData.js";

export default defineComponent({
  name: "EnterpriseNotice",
  components: {
    Attachment
  },
  emits: ["update"],
  setup(props, { emit }) {
    const goNextStep = () => {
      emit("update");
    };
    //
    const file = ref([
      {
        attachment_name: "唐山人力资源服务产业园入园申请表.docx",
        attachment_src:
          "https://cyyq.tsrc.net.cn/static/sheet/入驻申请表.docx"
      },
      {
        attachment_name: "企业（机构）对提交材料的真实性声明.docx",
        attachment_src:
          "https://cyyq.tsrc.net.cn/static/sheet/真实性声明.docx"
      }
    ]);
    const pageData = reactive({
      userData: computed(() => getUserData()),
      park_notice: null
    });

    const getParkDataFun = () => {
      getParkData({
        park_id: pageData.userData?.user_park_id
      })
        .then(res => {
          if (res.code == 200) {
            pageData.park_notice = res.data.park.park_notice;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {});
    };
    getParkDataFun();
    return { goNextStep, file, ...toRefs(pageData) };
  }
});
</script>

<style></style>
