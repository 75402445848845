<template>
  <div class="enterprise-info-page wrapper">
    <a-spin :spinning="loadingForm">
      <a-form
        :model="form"
        ref="formRef"
        layout="vertical"
        :colon="colon"
        @finish="handleSubmit"
      >
        <div class="info-wrap">
          <a-row type="flex" justify="space-between" :gutter="20">
            <!-- <a-col :span="12">
              <a-form-item
                name="park_id"
                label="选择园区"
                :rules="{
                  required: true,
                  message: '请选择'
                }"
              >
                <a-select
                  v-model:value="form.park_id"
                  size="large"
                  placeholder="请选择"
                >
                  <a-select-option
                    v-for="(item, index) in park"
                    :value="item.park_id"
                    :key="index"
                    >{{ item.park_name }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col> -->
            <a-col :span="24">
              <a-form-item
                name="enterprise_name"
                label="企业名称"
                :rules="{
                  required: true,
                  message: '请输入'
                }"
              >
                <a-input
                  v-model:value="form.enterprise_name"
                  placeholder="请输入"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item name="enterprise_logo" label="企业LOGO">
                <upload-image
                  v-model:imageList="form.enterprise_logo"
                  :imageCount="1"
                  :couldRename="false"
                  @change="getImageStatus('enterprise_logo')"
                ></upload-image>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item
                name="enterprise_usci"
                label="统一社会信用代码"
                :rules="{
                  required: true,
                  validator: validatePass
                }"
              >
                <a-input
                  v-model:value="form.enterprise_usci"
                  placeholder="请输入"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="enterprise_legal_person"
                label="法人姓名"
                :rules="{
                  required: true,
                  message: '请输入'
                }"
              >
                <a-input
                  v-model:value="form.enterprise_legal_person"
                  placeholder="请输入"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="enterprise_people_num"
                label="企业人数（人）"
                :rules="{
                  required: true,
                  message: '请输入'
                }"
              >
                <a-input
                  v-model:value="form.enterprise_people_num"
                  placeholder="请输入"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item
                name="enterprise_address"
                label="注册地址"
                :rules="{
                  required: true,
                  message: '请输入'
                }"
              >
                <a-input
                  v-model:value="form.enterprise_address"
                  placeholder="请输入"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="enterprise_contact_name"
                label="联系人姓名"
                :rules="{
                  required: true,
                  message: '请输入'
                }"
              >
                <a-input
                  v-model:value="form.enterprise_contact_name"
                  placeholder="请输入"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="enterprise_contact_phone"
                label="联系人电话"
                :rules="{
                  required: true,
                  message: '请输入'
                }"
              >
                <a-input
                  v-model:value="form.enterprise_contact_phone"
                  placeholder="请输入"
                  size="large"
                />
              </a-form-item>
            </a-col>

            <a-col :span="24">
              <a-form-item
                name="enterprise_introduction"
                label="企业简介"
                :rules="{ required: true, message: '请输入内容' }"
              >
                <a-textarea
                  v-model:value="form.enterprise_introduction"
                  size="large"
                  :auto-size="{ minRows: 4, maxRows: 4 }"
                  placeholder="输入内容......"
                  :maxlength="500"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="apply_sheet"
                label="唐山人力资源服务产业园入园申请表（附件1）"
                :rules="{
                  required: true,
                  message: '请上传'
                }"
              >
                <upload-image
                  v-model:imageList="form.apply_sheet"
                  :imageCount="1"
                  :couldRename="false"
                  @change="getImageStatus('apply_sheet')"
                ></upload-image>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="license"
                label="企业（机构）营业执照副本复印件"
                :rules="{
                  required: true,
                  message: '请上传'
                }"
              >
                <upload-image
                  v-model:imageList="form.license"
                  :imageCount="1"
                  :couldRename="false"
                  @change="getImageStatus('license')"
                ></upload-image>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="idcardimg"
                label="企业法人身份证复印件"
                :rules="{
                  required: true,
                  message: '请上传'
                }"
              >
                <upload-image
                  v-model:imageList="form.idcardimg"
                  :imageCount="2"
                  :couldRename="false"
                  @change="getImageStatus('idcardimg')"
                ></upload-image>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item
                name="permit"
                label="人力资源服务许可证或劳务派遣经营许可证等相关资质证件复印件"
                :rules="{
                  required: true,
                  message: '请上传'
                }"
              >
                <upload-image
                  v-model:imageList="form.permit"
                  :imageCount="9"
                  :couldRename="false"
                  @change="getImageStatus('permit')"
                ></upload-image>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item
                name="financial"
                label="经营性人力资源资源服务机构上一年度审计报告或财务报表（包含利润表和资产负债表）复印件"
                :rules="{
                  required: true,
                  message: '请上传'
                }"
              >
                <upload-image
                  v-model:imageList="form.financial"
                  :imageCount="9"
                  :couldRename="false"
                  @change="getImageStatus('financial')"
                ></upload-image>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="honor"
                label="企业（机构）简介及所获荣誉证书复印件"
                :rules="{
                  required: true,
                  message: '请上传'
                }"
              >
                <upload-image
                  v-model:imageList="form.honor"
                  :imageCount="9"
                  :couldRename="false"
                  @change="getImageStatus('honor')"
                ></upload-image>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="real"
                label="企业（机构）对提交材料的真实性声明（附件2）"
                :rules="{
                  required: true,
                  message: '请上传'
                }"
              >
                <upload-image
                  v-model:imageList="form.real"
                  :imageCount="1"
                  :couldRename="false"
                  @change="getImageStatus('real')"
                ></upload-image>
              </a-form-item>
            </a-col>
            <a-col :span="12" v-if="data?.enterprise_enter_status!=-1">
              <a-form-item
                name="enterprise_user_name"
                label="管理员姓名"
                :rules="{
                  required: true,
                  message: '请输入'
                }"
              >
                <a-input
                  v-model:value="form.enterprise_user_name"
                  placeholder="请输入"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12" v-if="data?.enterprise_enter_status!=-1">
              <a-form-item
                name="enterprise_user_phone"
                label="管理员电话"
                :rules="{
                  required: true,
                  message: '请输入'
                }"
              >
                <a-input
                  v-model:value="form.enterprise_user_phone"
                  placeholder="请输入"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12" v-if="data?.enterprise_enter_status!=-1">
              <a-form-item
                label="管理员职位"
              >
                <a-input
                  v-model:value="form.enterprise_user_job"
                  placeholder="请输入"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item class="">
                <a-button
                  block
                  size="large"
                  type="primary"
                  html-type="submit"
                  class="btn"
                  :loading="loadingBtn"
                  @click="handleSubmit"
                >
                  提交审核
                </a-button>
              </a-form-item>
            </a-col>
          </a-row>
        </div>
      </a-form>
    </a-spin>
  </div>
</template>
<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
  ref,
  createVNode
} from "@vue/runtime-core";
import {
  insertEnterpriseData,
  getParkList,
  updateEnterpriseFirstData,
} from "@/api/main.js";
import { getUserId, getUserData } from "@/utils/storeData.js";
import url from "@/utils/url.js";
import { message, Modal } from "ant-design-vue";
import { encode, decode } from "js-base64";
import { useRoute, useRouter } from "vue-router";
import uploadImage from "@/components/upload.vue";

export default defineComponent({
  name: "EnterpriseInfo",
  components: {
    uploadImage
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {};
      }
    },
    enterpriseId: {
      type: String,
      default: null
    }
  },
  emits: ["update"],
  setup(props, { emit }) {
    const formRef = ref();
    const route = useRoute();
    const previewImage = ref("");
    const pageData = reactive({
      colon: false,
      autosize: false,
      fileList: [],
      form: {
        // park_id: null,
        enterprise_name: null,
        enterprise_logo: null,
        enterprise_usci: null,
        enterprise_legal_person: null,
        enterprise_people_num: null,
        enterprise_introduction: null,
        enterprise_contact_name: null,
        enterprise_contact_phone: null,
        enterprise_address: null,
        apply_sheet: null,
        license: null,
        idcardimg: null,
        permit: null,
        financial: null,
        honor: null,
        real: null,
        enterprise_user_phone: null,
        enterprise_user_name: null,
        enterprise_user_job: null
      },
      loadingBtn: false,
      loadingForm: false,
      user_id: computed(() => getUserId()),
      userData: computed(() => getUserData()),
      park: null
    });
    const getParkListFun = () => {
      getParkList()
        .then(res => {
          if (res.code == 200) {
            pageData.park = res.data.park;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    getParkListFun();

    const insertEnterpriseDataFun = () => {
      formRef.value
        .validate()
        .then(res => {
          pageData.loadingBtn = true;
          pageData.loadingForm = true;
          let enterprise_enter_material = {
            apply_sheet: pageData.form.apply_sheet,
            license: pageData.form.license,
            idcardimg: pageData.form.idcardimg,
            permit: pageData.form.permit,
            financial: pageData.form.financial,
            honor: pageData.form.honor,
            real: pageData.form.real
          };
          insertEnterpriseData({
            ...res,
            user_id: pageData.user_id,
            enterprise_enter_material: JSON.stringify(
              enterprise_enter_material
            ),
            park_id: pageData.userData?.user_park_id
          }).then(res => {
            message.success("提交成功");
            url.replaceTo("/enterprise/flow", {
              t: encode(`${res.data.enterprise_id}`)
            });
          });
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {
          pageData.loadingBtn = false;
          pageData.loadingForm = false;
        });
    };
    const updateEnterpriseFirstDataFun = () => {
      formRef.value
        .validate()
        .then(res => {
          pageData.loadingBtn = true;
          pageData.loadingForm = true;
          let enterprise_enter_material = {
            apply_sheet: pageData.form.apply_sheet,
            license: pageData.form.license,
            idcardimg: pageData.form.idcardimg,
            permit: pageData.form.permit,
            financial: pageData.form.financial,
            honor: pageData.form.honor,
            real: pageData.form.real
          };
          updateEnterpriseFirstData({
            ...res,
            user_id: pageData.user_id,
            enterprise_id: props.enterpriseId,
            enterprise_enter_material: JSON.stringify(
              enterprise_enter_material
            ),
            park_id: pageData.userData?.user_park_id
          }).then(res => {
            message.success("提交成功");
            url.replaceTo("/enterprise/flow", {
              t: encode(`${res.data.enterprise_id}`)
            });
          });
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {
          pageData.loadingBtn = false;
          pageData.loadingForm = false;
        });
    };
    const handleSubmit = e => {
      e.preventDefault();
      if (props.enterpriseId) {
        updateEnterpriseFirstDataFun();
      } else {
        insertEnterpriseDataFun();
      }
    };
    const getImageStatus = e => {
      formRef.value.clearValidate(e);
    };
    const validatePass = async (rule, value) => {
      let regexp = /^[A-Z0-9]{18}$/;
      if (regexp.test(value)) {
        return Promise.resolve();
      } else {
        return Promise.reject("请输入正确的统一社会信用代码");
      }
    };
    watch(
      () => props.data,
      val => {
        if (val) {
          pageData.form = { ...props.data };
          //
          let enterprise_enter_material = JSON.parse(
            props.data.enterprise_enter_material
          );
          pageData.form.apply_sheet = enterprise_enter_material.apply_sheet;
          pageData.form.license = enterprise_enter_material.license;
          pageData.form.idcardimg = enterprise_enter_material.idcardimg;
          pageData.form.permit = enterprise_enter_material.permit;
          pageData.form.financial = enterprise_enter_material.financial;
          pageData.form.honor = enterprise_enter_material.honor;
          pageData.form.real = enterprise_enter_material.real;
        }
      },
      { immediate: true }
    );

    return {
      ...toRefs(pageData),
      formRef,
      handleSubmit,
      getImageStatus,
      validatePass
    };
  }
});
</script>
<style lang="less">
.enterprise-info-page {
  .info-wrap {
    width: 1000px;
    background-color: #ffffff;
    border-radius: 10px;
    margin: 40px auto;
    padding: 20px;
    box-sizing: border-box;
    .input-hide {
      visibility: hidden;
      position: absolute;
      top: -999px;
      left: -999px;
    }
  }
}
</style>
