<template>
  <div class="enterprise-apply-wrap wrapper">
    <div class="step-box">
      <a-steps :current="menuActive">
        <a-step title="入驻须知" />
        <a-step title="填写信息" />
        <!-- <a-step title="上传资料" /> -->
        <a-step title="签署合同" />
      </a-steps>
    </div>
    <div class="apply-main">
      <notice v-if="menuActive == 0" @update="goInfoStep"></notice>
      <info
        v-if="menuActive == 1"
        :data="enterprise"
        :enterpriseId="enterpriseId"
      ></info>
      <!-- <upload
        v-if="menuActive == 2"
        :data="enterprise"
        :enterpriseId="enterpriseId"
      ></upload> -->
      <contract v-if="menuActive == 2"></contract>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  watch
} from "@vue/runtime-core";
import { useRoute } from "vue-router";
import { getUserData, getUserId } from "@/utils/storeData.js";
import { getEnterpriseData } from "@/api/main.js";
import { message } from "ant-design-vue";
import { encode, decode } from "js-base64";
import Notice from "./components/notice.vue";
import Info from "./components/info.vue";
// import Upload from "./components/upload.vue";
import Contract from "./components/contract.vue";
export default defineComponent({
  name: "EnterpriseApply",
  components: {
    Notice,
    Info,
    // Upload,
    Contract
  },
  setup() {
    const route = useRoute();
    // console.log("route", route);
    const pageData = reactive({
      userData: computed(() => getUserData()),
      user_id: computed(() => getUserId()),
      menuActive: 0,
      enterprise: null,
      enterpriseId: null
    });
    const goInfoStep = () => {
      pageData.menuActive = 1;
    };
    const getEnterpriseDataFun = _enterpriseId => {
      getEnterpriseData({
        enterprise_id: _enterpriseId,
        user_id: pageData.user_id
      })
        .then(res => {
          if (res.code == 200) {
            pageData.enterprise = res.data.enterprise;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        });
    };
    watch(
      () => route.query,
      val => {
        if (route.path == "/enterprise/apply") {
          if (val?.t) {
            let query = decode(val?.t).split(",");
            if (query[0]) {
              pageData.loading = true;
              pageData.enterpriseId = query[0];
              pageData.menuActive = query[1];
              getEnterpriseDataFun(query[0]);
            } else {
              pageData.loading = false;
              pageData.enterprise = null;
              message.error("暂无企业入驻信息");
            }
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    return {
      ...toRefs(pageData),
      goInfoStep
    };
  }
});
</script>

<style lang="less">
.enterprise-apply-wrap {
  padding-top: 70px;
  padding-bottom: 50px;
  .step-box {
    width: 1000px;
    margin: 0 auto;
    .ant-steps-item-title {
      font-size: 20px;
    }
  }
  .apply-main {
    margin-top: 80px;
  }
}
</style>
